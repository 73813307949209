import * as React from 'react';
import { SVGProps } from 'react';

const SvgIcoTechnical = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m407.36 755.85 55.969-111.92-25.762-17.172-57.938 101.36z" />
            <path d="m487.5 168.75c0-14.918-5.9258-29.227-16.477-39.773-10.547-10.551-24.855-16.477-39.773-16.477h-150c-14.918 0-29.227 5.9258-39.773 16.477-10.551 10.547-16.477 24.855-16.477 39.773v56.25h262.5z" />
            <path d="m187.5 393.75v-75c-13.398 0-25.777 7.1484-32.477 18.75-6.6992 11.602-6.6992 25.898 0 37.5 6.6992 11.602 19.078 18.75 32.477 18.75z" />
            <path d="m356.25 543.75c34.797-0.042969 68.152-13.887 92.758-38.492 24.605-24.605 38.449-57.961 38.492-92.758v-99.695c-14.945 18.254-33.562 33.164-54.637 43.766 5.7188 0.39063 10.957 3.3359 14.266 8.0156 3.3047 4.6797 4.3359 10.602 2.7969 16.121-1.5391 5.5234-5.4766 10.059-10.73 12.355-5.25 2.2969-11.258 2.1094-16.355-0.50781-5.0977-2.6211-8.7461-7.3945-9.9375-13-1.1914-5.6055 0.20312-11.453 3.7969-15.918-14.973 5.6406-30.664 9.1445-46.613 10.406l-6.5625 19.707h11.477c6.6992 0 12.887 3.5742 16.238 9.375 3.3477 5.8008 3.3477 12.949 0 18.75-3.3516 5.8008-9.5391 9.375-16.238 9.375h-37.5c-6.0234-0.003906-11.68-2.9023-15.199-7.7891-3.5195-4.8906-4.4805-11.172-2.5742-16.887l11.25-33.523c-12.004-1.8516-23.773-4.9922-35.102-9.375 2.6016 3.207 4.0547 7.1953 4.125 11.324 0.066406 4.8945-1.7852 9.6172-5.1562 13.164-3.3711 3.5508-7.9961 5.6367-12.887 5.8203s-9.6602-1.5508-13.289-4.8359c-3.6289-3.2812-5.8281-7.8555-6.1289-12.738-0.30469-4.8867 1.3164-9.6953 4.5117-13.402 3.1914-3.707 7.7109-6.0195 12.586-6.4375-21.074-10.602-39.691-25.512-54.637-43.766v99.695c0.042969 34.797 13.887 68.152 38.492 92.758 24.605 24.605 57.961 38.449 92.758 38.492zm-50.758-85.031c3.5195-3.5156 8.2852-5.4883 13.258-5.4883s9.7383 1.9727 13.258 5.4883c6.4336 6.418 15.152 10.023 24.242 10.023s17.809-3.6055 24.242-10.023c4.7656-4.6016 11.598-6.3477 17.984-4.5977 6.3867 1.7539 11.375 6.7422 13.125 13.125 1.75 6.3867 0.003907 13.223-4.5938 17.984-13.477 13.438-31.73 20.98-50.758 20.98s-37.281-7.543-50.758-20.98c-3.5117-3.5156-5.4883-8.2852-5.4883-13.254 0-4.9727 1.9766-9.7422 5.4883-13.258z" />
            <path d="m356.25 337.5c24.707 0.082031 48.941-6.7617 69.957-19.75 21.016-12.984 37.977-31.598 48.957-53.73l0.76563-1.5195h-239.36l0.76953 1.5195h-0.003906c10.98 22.133 27.941 40.746 48.957 53.73 21.016 12.988 45.25 19.832 69.957 19.75z" />
            <path d="m249.17 643.93 55.969 111.92 27.73-27.734-57.938-101.36z" />
            <path d="m415.5 806.01c-0.99219 0.16406-1.9961 0.24219-3 0.24219-4.9727 0-9.7422-1.9766-13.258-5.4922l-24.242-24.246v179.74h150v-286.5l-27.992-9.375-67.633 135.51c-2.7148 5.418-7.8867 9.1914-13.875 10.125zm34.5 112.74h-37.5v-37.5h37.5z" />
            <path d="m412.5 595.03v-23.625c-36.352 13.125-76.148 13.125-112.5 0v23.625l56.25 98.438z" />
            <path d="m150.41 977.08c-0.17578-0.6875-0.31641-1.3828-0.41406-2.082v-150h-75v225c0 4.9727 1.9766 9.7422 5.4922 13.258 3.5156 3.5156 8.2852 5.4922 13.258 5.4922h86.492l-29.266-87.824c-0.32812-1.2578-0.51562-2.5469-0.5625-3.8438z" />
            <path d="m726.79 862.5c0-6.1484 0.26172-12.414 0.84375-18.75h-52.633v37.5h52.633c-0.58203-6.3359-0.84375-12.602-0.84375-18.75z" />
            <path d="m997.76 956.04c-1.082-0.050781-2.1602-0.1875-3.2227-0.41016-0.82422-0.26953-1.625-0.59766-2.4023-0.97656-0.71484-0.25391-1.4141-0.55078-2.0977-0.88281h-0.09375l-64.949-37.5h-0.003906c-5.8008-3.3477-9.375-9.5391-9.375-16.234v-75.039c0-0.50781 0.14844-0.97656 0.1875-1.4609 0.039062-0.48828 0.13281-1.125 0.22656-1.707 0.33594-2.168 1.0391-4.2617 2.082-6.1875 1.1484-1.8984 2.6289-3.5742 4.3672-4.9492 0.40625-0.35156 0.83203-0.67969 1.2773-0.97656 0.38672-0.33203 0.79688-0.64453 1.2188-0.9375l64.949-37.5c2.8711-1.6641 6.1289-2.5391 9.4492-2.5312h114.73c-21.465-37.141-55.02-65.797-95.066-81.176-40.047-15.383-84.16-16.559-124.97-3.332-40.809 13.223-75.844 40.051-99.258 75.996-3.4609 5.3125-9.3711 8.5117-15.711 8.5117h-104.1v37.5h74.102c5.5703-0.003906 10.855 2.4727 14.422 6.7539 3.5664 4.2812 5.0391 9.9297 4.0273 15.41-4.3516 22.516-4.3516 45.656 0 68.172 1.0117 5.4805-0.46094 11.129-4.0273 15.41-3.5664 4.2812-8.8516 6.7578-14.422 6.7539h-74.102v37.5h104.04c6.3359-0.003906 12.246 3.1914 15.715 8.4922 23.379 35.992 58.41 62.859 99.23 76.109 40.824 13.246 84.957 12.074 125.02-3.3281 40.059-15.398 73.613-44.09 95.047-81.273h-114.68c-0.58203 0-1.0859-0.16797-1.6133-0.20703zm-65.531 37.707c-1.418 4.7617-4.6719 8.7656-9.0391 11.133-4.3711 2.3672-9.5039 2.9023-14.266 1.4844-41.586-12.367-75.777-42.137-93.754-81.621s-17.977-84.82 0-124.3 52.168-69.254 93.754-81.621c6.3398-1.6719 13.094 0.09375 17.805 4.6523 4.7148 4.5586 6.7031 11.246 5.25 17.641-1.457 6.3945-6.1445 11.562-12.367 13.629-31.168 9.293-56.793 31.617-70.262 61.219-13.473 29.602-13.473 63.586 0 93.191 13.469 29.602 39.094 51.926 70.262 61.215 4.7773 1.4219 8.7969 4.6836 11.164 9.0703 2.3672 4.3867 2.8906 9.5391 1.4531 14.312z" />
            <path d="m953.12 889.18 46.203 26.664 27.449-15.844-73.652-42.523z" />
            <path d="m1045.5 835.82-46.219-26.664-27.453 15.844 73.672 42.523z" />
            <path d="m562.5 676.88v110.62h75v-18.75c-0.046875-21.598-7.5312-42.523-21.188-59.254-13.66-16.73-32.66-28.25-53.812-32.621z" />
            <path d="m252.13 920.72 71.043 35.531h14.324v-179.74l-24.242 24.242v0.003906c-3.5156 3.5156-8.2852 5.4922-13.258 5.4922-1.0039 0-2.0078-0.078125-3-0.24219-5.9531-0.96484-11.082-4.7305-13.781-10.125l-67.727-135.45-27.992 9.3164v274.91l47.867-23.945c5.2773-2.6367 11.488-2.6367 16.766 0z" />
            <path d="m562.5 975c0 4.9727-1.9766 9.7422-5.4922 13.258-3.5156 3.5156-8.2852 5.4922-13.258 5.4922h-225c-2.9023 0.03125-5.7695-0.60938-8.3828-1.875l-66.617-33.414-52.07 26.043 28.09 84.246h398.98c4.9727 0 9.7422-1.9766 13.258-5.4922 3.5156-3.5156 5.4922-8.2852 5.4922-13.258v-225h-75z" />
            <path d="m562.5 356.25c0-9.9453-3.9492-19.484-10.984-26.516-7.0312-7.0352-16.57-10.984-26.516-10.984v75c9.9453 0 19.484-3.9492 26.516-10.984 7.0352-7.0312 10.984-16.57 10.984-26.516z" />
            <path d="m150 676.88c-21.16 4.3516-40.176 15.867-53.836 32.602-13.664 16.734-21.137 37.668-21.164 59.273v18.75h75z" />
        </g>
    </svg>

);

export default SvgIcoTechnical;
