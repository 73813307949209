import { configureStore } from "@reduxjs/toolkit";
import { Root } from "react-dom/client";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { useDispatch } from 'react-redux'
import storage from 'redux-persist/lib/storage';
 
import api from "./middleware/api";
import reducer from "./reducer";
import api2 from "./middleware/api2";

const persistConfig = {
  key: "root",
  version: 1,
  storage
};

//const persistedReducer = persistReducer(persistConfig, reducer);

export type RootState = ReturnType<typeof reducer>;
 
  const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([api,api2]),
  });

//export let persistor = persistStore(store);

export const useAppDispatch: () => AppDispatch = useDispatch 
 
export type AppDispatch = typeof store.dispatch;

export default store ;

